import * as React from "react"

import Seo from "../components/seo"

import styled from "styled-components";

import RandomNamePicker from "../components/randomNamePicker";
import Header2 from "../components/header2";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: space-around;
	flex-direction: column;
	height: 100vh;
`

const Heading = styled.h1`
	text-align: center;
`

const BierHalen = () => (
		<Wrapper>
			<Header2/>
			<Heading>
				Wie moet er bier halen?
			</Heading>
			<RandomNamePicker/>
		</Wrapper>		
)

export const Head = () => <Seo title="Wie moet er bier halen?" />

export default BierHalen
