import React from "react"
import styled from 'styled-components';

const IconSvg = styled.svg`
`

const Arrow = () => {
    return (
        <IconSvg 
            id="uuid-30ef176c-bf8d-42a7-adc1-aeb1f6139248" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 634.15757 386.71359"
        >
            <g 
                id="uuid-7d0f61a7-6026-4c3d-83d6-dcf847d5ef60"
            >
            <path 
                d="M0,329.85387c.95153-9.88869,1.69126-19.80323,2.89601-29.66105,3.08235-25.22126,6.3783-50.41643,9.5769-75.62354,6.99917-55.1583,11.15495-110.45862,7.23746-166.07108-1.04939-14.89705-3.25143-29.71305-4.92182-44.56653-.08633-.76766-.1491-1.53796-.22284-2.30703,.4527-.35966,.90539-.71932,1.35809-1.07898,.28098,.81026,.36424,1.77976,.87128,2.40795,26.24311,32.51415,45.93284,68.23714,54.37055,109.59021,5.88207,28.82795,5.82451,57.78468,2.19471,86.80503-3.11169,24.8782-6.98327,49.66666-9.75617,74.57953-1.11139,9.98513-.18289,20.19676-.18289,31.51999,6.87821-1.11852,13.06787-1.7993,19.10288-3.16303,25.67853-5.80264,49.25548-16.96742,72.36026-29.12754,31.6073-16.63508,62.8741-33.92015,94.58205-50.35843,22.30898-11.56561,45.5193-21.10184,70.33117-26.08814,18.21386-3.66031,36.32384-4.03591,54.10757,2.37914,2.11343,.76236,4.16925,1.68403,6.98883,2.83194-9.10302,4.55359-17.70117,8.09435-25.52004,12.9002-30.43064,18.70407-60.85372,37.4403-90.83033,56.85673-39.75116,25.74758-78.69614,52.77258-120.68013,74.90816-22.37668,11.7978-45.24678,22.46076-70.26812,27.55233-5.18484,1.05508-10.44803,1.72594-15.6751,2.57388h-8.98763c-1.77017-.34016-3.52672-.80094-5.31257-1.00232-23.33705-2.63139-42.98113-23.12563-42.83301-44.52699,.01232-1.77982-.51207-3.56335-.78709-5.34518,0-1.99508,0-3.99016,0-5.98523Z"
                fill="var(--light)"
            />
            <path 
                d="M634.14031,198.27991c-9.50176-9.03076-18.18415-17.93768-27.54115-26.07021-63.00176-54.75729-135.24569-90.61802-217.8554-104.33611-42.86361-7.11788-85.58355-5.94915-127.19714,8.02703-51.81334,17.40184-89.01351,51.63951-113.94407,99.84278-12.2037,23.59587-18.9344,49.18797-27.74231,74.02621-1.23814,3.49152-2.4308,6.99917-3.64451,10.49935-.41544-.04859-.83088-.09717-1.24632-.14579-.88533-8.98531-2.01225-17.95453-2.61374-26.95878-2.4693-36.96507-.09309-73.4312,12.88919-108.5281,20.8132-56.26744,61.29949-92.01172,117.48969-110.84388C281.9808,.63903,322.38642-2.19113,363.41259,1.47489c56.80128,5.07566,110.07701,21.46133,159.37945,50.14856,35.074,20.40826,65.50979,46.05013,87.62895,80.58826,11.8763,18.54431,19.83264,38.63327,23.34112,60.41902,.15823,.98282,.2833,1.97322,.36766,2.96485,.05565,.65456,.01054,1.31764,.01054,2.68433Z"
                fill="var(--light)"
            />
            </g>
        </IconSvg>
    )
}

export default Arrow
