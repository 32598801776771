import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components";
import "../components/layout.css"

const HeaderWrapper = styled.div`
	position: fixed; 
	top: 0;
	left: 0;
	width: 100%;
	padding: 16px;
	text-align: center;
	text-decoration: underline;
`

const Header2 = () => (
	<HeaderWrapper>
		<Link
			to="/"
			style={{
				fontSize: `var(--font-sm)`,
				textDecoration: `none`,
			}}
		>
			Take me home, country roads...
		</Link>
	</HeaderWrapper>
)

export default Header2
