import React from "react";
import Confetti from "react-confetti";

import styled from "styled-components";
import Arrow from "./icons/arrow";

import "./layout.css"

const Wrapper = styled.div`
`

const RandomNameWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;
`

const Button = styled.button`
	background: var(--light);
	color: var(--dark);
	padding: 8px;
	border-radius: 8px;
	border: none;
	font-size: 22px;
	width: 300px;
	font-weight: bold;
	font-family: var(--font-mono);

	&:active {
		background: red;
	}
`

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	& svg {
		max-width: 30px;
		transform: rotate(180deg);
	}
`

const ClickText = styled.span`
	font-size: 22px;
`

const names = [
	"Marijn",
	"Mark",
	"Daniël",
	"Thomas",
	"Zaling",
	"Chris",
	"Marten",
	"Melvin",
	"Joost",
	"Lorenz"
];

const INTERVAL = 50;

const NamePicker = ({ names, interval }) => {
	const [name, setName] = React.useState(names[0]);
	const [isRendering, setIsRendering] = React.useState(true);

	React.useEffect(() => {
		if (isRendering) {
			const i = setInterval(() => {
				setName(names[Math.floor(Math.random() * names.length)]);
			}, interval);
			return () => clearInterval(i);
		}
	});
	return (
		<div>
			<Confetti
				style={!isRendering ? { display: "block" } : { display: "none" }}
			/>
			<Button onClick={() => setIsRendering(!isRendering)} className="names">
				{isRendering ? name : name}
			</Button>
		</div>
	);
};

export default function RandomNamePicker() {
	return (
		<Wrapper>
			<RandomNameWrapper className="RandomNamePicker">
				<NamePicker names={names} interval={INTERVAL} />
			</RandomNameWrapper>
			<TextWrapper className="TextWrapper">
				<ClickText>
					Click!
				</ClickText>
				<Arrow/>
			</TextWrapper>
		</Wrapper>
	);
}
